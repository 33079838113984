import React from "react"
import { FaGithub, FaLinkedin, FaMedium } from "react-icons/fa"
import { Navbar, Col, Image, Row } from "react-bootstrap"
import { Link } from "gatsby"
import { Location } from "@reach/router"

const LbsNavbar = () => {
  const onScroll = () => {
    const navHeader = document.getElementById("nav-header")
    const collapse = document.getElementById("nav_collapse")
    if (collapse.scrollTop > 50) {
      navHeader.classList.remove("d-flex")
      navHeader.classList.add("d-none")
    } else {
      navHeader.classList.remove("d-none")
      navHeader.classList.add("d-flex")
    }
  }

  return (
    <Location>
      {({ location }) => (
        <Navbar
          expand="lg"
          variant="dark"
          className="navigation-wrapper py-3 py-lg-4"
        >
          <div
            className="d-flex row w-100  justify-content-between"
            id="nav-header"
          >
            <div id="logo-wrapper">
              <Link to="/">
                <img src="/images/logo-white.svg" alt="" className="logo " />
              </Link>
            </div>
            <Navbar.Toggle aria-controls="nav_collapse" />
          </div>
          <Navbar.Collapse id="nav_collapse" onScroll={onScroll}>
            <ul className="navbar-nav ml-auto align-items-lg-center">
              <li className="nav-item">
                <Link
                  to="/"
                  className="nav-link"
                  activeClassName={location.pathname === "/" ? "active" : ""}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/services/"
                  className="nav-link"
                  activeClassName={
                    location.pathname === "/services/" ? "active" : ""
                  }
                >
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/careers/"
                  className="nav-link"
                  activeClassName={
                    location.pathname === "/careers/" ? "active" : ""
                  }
                >
                  Careers
                </Link>
              </li>
              <li className="nav-item highlight">
                <Link
                  to="/blog/"
                  className="nav-link"
                  activeClassName={
                    location.pathname === "/blog/" ? "active" : ""
                  }
                >
                  Blog
                </Link>
              </li>
              <li className="nav-item d-none d-lg-block">
                <a target="_self" href="#contact-us" className="nav-link">
                  Contact Us
                </a>
              </li>
              <li className="nav-item d-block d-lg-none">
                <Link
                  to="/contact/"
                  className="nav-link"
                  activeClassName={
                    location.pathname === "/contact/" ? "active" : ""
                  }
                >
                  Contact Us
                </Link>
              </li>
              <Row className="d-lg-none py-5 mobile-social-wrapper">
                <Col xs={{ span: 12 }}>
                  <a
                    href="https://linkedin.com/company/labs-42"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-flex w-50 align-items-center justify-content-between"
                  >
                    Linkedin
                    <Image
                      height={12}
                      width={13}
                      src="/images/icons/arrow-linkedin-link.svg"
                      alt=""
                    />
                  </a>
                </Col>
                <Col xs={{ span: 12 }}>
                  <a
                    href="https://github.com/labs42io"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-flex w-50 align-items-center justify-content-between"
                  >
                    Github
                    <Image
                      height={12}
                      width={13}
                      src="/images/icons/arrow-linkedin-link.svg"
                      alt=""
                    />
                  </a>
                </Col>
                <Col xs={{ span: 12 }}>
                  <a
                    href="https://medium.com/labs42"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-flex w-50 align-items-center justify-content-between"
                  >
                    Medium
                    <Image
                      height={12}
                      width={13}
                      src="/images/icons/arrow-linkedin-link.svg"
                      alt=""
                    />
                  </a>
                </Col>
              </Row>
              <Row className="d-lg-none pb-5">
                <Col xs={{ span: 12 }} className="header-address">
                  <p className="m-0 country text-left mt-2">
                    Republic of Moldova
                  </p>
                  <p className="m-0 address text-left">
                    str.Albisoara 42, 3rd floor <br />
                    Chisinau, 2005
                  </p>
                </Col>
              </Row>
              <div className="d-none d-lg-flex social-wrapper">
                <a
                  href="https://github.com/labs42io"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-link"
                >
                  <FaGithub />
                </a>
                <a
                  href="https://linkedin.com/company/labs-42"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-link"
                >
                  <FaLinkedin />
                </a>
                <a
                  href="https://medium.com/labs42"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-link"
                >
                  <FaMedium />
                </a>
              </div>
            </ul>
          </Navbar.Collapse>
        </Navbar>
      )}
    </Location>
  )
}
export default LbsNavbar
