import React from "react"
import PropTypes from "prop-types"
import "@scss/bootstrap/bootstrap.min.scss"
import "@scss/layout.scss"
import "@scss/careers.scss"
import "@scss/services.scss"
import "@scss/team.scss"
import "@scss/index.scss"
import "@scss/blog.scss"
import CookieConsent from "react-cookie-consent"

const Layout = ({ children }) => {
  return (
    <>
      <main>{children}</main>
      <CookieConsent
        location="bottom"
        enableDeclineButton={true}
        flipButtons={true}
        cookieName="gatsby-gdpr-google-analytics"
        buttonText="Accept"
        buttonStyle={{
          background: "#02eed6",
          color: "white",
          padding: "0.5rem 1rem",
        }}
        declineButtonStyle={{
          background: `url(/images/icons/close.svg) no-repeat center`,
          padding: "1rem",
        }}
        declineButtonText=""
        style={{
          alignItems: "center",
          background: "rgba(14, 22, 33, 0.8)",
        }}
      >
        <p className="text-center">
          Our site uses cookies. By continuing to browse the site, you agree to
          our use of cookies.
        </p>
      </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
